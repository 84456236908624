import { AxiosResponse } from 'axios';
import camelCaseKeys from 'camelcase-keys';
import { HttpStatus } from 'constants/http-status';
import { EffectiveCartPayload } from 'hooks/effective-cart/useEffectiveCart';
import { EffectiveCart } from 'interfaces/cart';
import { CustomerSubscriptionStatus } from 'interfaces/customer';
import {
  CartDiscountType,
  OperationOptions,
  PaymentResponseType,
  PaymentSessionRequest,
} from 'modules/checkout/payment/types';
import { axios, axiosWithoutRequestInterceptor } from 'utils/axios';
import {
  ApplePaymentPaymentRequest,
  ExpressCheckout,
  ExpressCheckoutSummary,
  PetsDeliCheckout,
} from '../interfaces/checkout';
import * as CheckoutModel from '../models/checkout';

interface CheckoutSessionBaseResponse {
  clientIp: string;
  landingPageUrl: string;
}

type AnonymousSession = CheckoutSessionBaseResponse;

export function isIdentifiedCustomer(
  data: any
): data is IdentifiedCustomerSession {
  return 'customerId' in data;
}

export interface IdentifiedCustomerSession extends CheckoutSessionBaseResponse {
  createdAt: number;
  customerId: number;
  email: string;
  hasLoyaltyPoints: boolean;
  ordersCount: number;
  subscriptionStatus: CustomerSubscriptionStatus;
  firstName?: string;
  lastName?: string;
  phone?: string;
  postcode?: string;
  street?: string;
  city?: string;
}

export type CheckoutSessionResponse =
  | AnonymousSession
  | IdentifiedCustomerSession;

/**
 * Create a session on backend and sets cookies related to session.
 * @returns CheckoutSessionResponse
 * @see https://petsdeli.atlassian.net/wiki/spaces/DOKUMENTAT/pages/1103331353/
 *
 * Due to BE bug, API might returns bad gate way error temporally.
 * The error toast is omitted as it's not critical for user.
 * @see https://petsdeli.atlassian.net/browse/PT-4078
 */
export const getCheckoutSession = (): Promise<CheckoutSessionResponse> =>
  axios
    .get<CheckoutSessionResponse>(
      `${process.env.API_ORIGIN_CHECKOUT}/getSession`,
      {
        params: { ref: window.location.href },
        errorHandling: {
          statusesToOmit: [HttpStatus.BAD_GATEWAY],
        },
      }
    )
    .then((response) => response.data);

export const createPetsdeliCheckout = ({
  items,
  cid,
  referalToken,
  gifts,
  shopifyCheckoutId,
}: any): any =>
  axios
    .post(`${process.env.API_ORIGIN_CHECKOUT}/checkouts`, {
      cid,
      shopifyCheckoutId,
      referalToken,
      cart: { items, gifts },
    })
    .then((response) => response.data);

export const getPetsdeliCheckout = ({
  uuid,
  audiences,
}: {
  uuid: string;
  audiences: string[];
}): Promise<AxiosResponse<PetsDeliCheckout>> =>
  axios.get(`${process.env.API_ORIGIN_CHECKOUT}/checkouts/${uuid}`, {
    headers: {
      audiences: audiences.join(','),
    },
    // Errors are handled in react level.
    errorHandling: {
      statusesToOmit: [
        HttpStatus.BAD_REQUEST,
        HttpStatus.INTERNAL_SERVER_ERROR,
      ],
    },
  });

export const claimPetsDeliCheckout = ({
  cid,
  uuid,
}: {
  cid: number;
  uuid: string;
}): Promise<AxiosResponse<PetsDeliCheckout>> =>
  axios.post(`${process.env.API_ORIGIN_CHECKOUT}/checkouts/${uuid}/claim`, {
    cid,
  });

export const createExpressCheckout = ({
  code,
  checkoutId,
  audiences,
  ...effectiveCartPayload
}: {
  checkoutId: string;
  [effectiveCartPayloadKey: string]: any;
}): any =>
  axios
    .post(
      `${process.env.API_ORIGIN_EXPRESS_CHECKOUT}/preset`,
      {
        listParams: {
          summaryUrl: `${location.protocol}//${location.host}/checkout/express`,
          cancelUrl: `${location.protocol}//${location.host}/cart`,
          returnUrl: `${location.protocol}//${location.host}/checkout/express`,
        },
        code,
        checkoutId,
        cart: effectiveCartPayload,
      },
      {
        headers: {
          shopid: process.env.SHOP_ID,
          audiences: audiences.join(','),
        },
      }
    )
    .then((response) => response.data);

export type ExpressCheckoutInitInput = {
  providerResponse: { [key: string]: string };
};

/**
 * Initialize Checkout for express checkout.
 * This API call creates checkout data on our backend and create a user if necessary
 */
export const expressCheckoutInit = ({
  providerResponse,
}: ExpressCheckoutInitInput): Promise<ExpressCheckout> =>
  axios
    .get(
      `${process.env.API_ORIGIN_EXPRESS_CHECKOUT}/summary/${providerResponse.requestId}`,
      {
        headers: {
          shopid: process.env.SHOP_ID,
        },
      }
    )
    .then((response) => response.data)
    .then(CheckoutModel.expressCheckoutFromAmazonFormat);

interface ExpressCheckoutConfirmInput {
  cart: EffectiveCartPayload;
  subscribeNewsletter: boolean;
  captureUrl: string;
  audiences: Array<string>;
}
interface ExpressCheckoutOutput {
  redirect: string;
  status: boolean;
}

export const expressCheckoutConfirm = ({
  audiences,
  cart,
  captureUrl,
  subscribeNewsletter,
}: ExpressCheckoutConfirmInput): Promise<ExpressCheckoutOutput> =>
  axios
    .post(
      captureUrl,
      {
        subscribeNewsletter,
        cart,
      },
      {
        headers: {
          shopid: process.env.SHOP_ID,
          audiences: audiences.toString(),
        },
      }
    )
    .then((response) => response.data);

export const expressCheckoutCharge = ({ chargeUrl }: any) =>
  axios
    .post(chargeUrl, undefined, { headers: { shopid: process.env.SHOP_ID } })
    .then(({ data }) => data);
export const expressCheckoutConfirmAuthentication = (
  confirmUrl: string,
  data: any
) => axios.post(confirmUrl, data, { headers: { shopid: process.env.SHOP_ID } });

export const expressCheckoutSummary = ({
  summaryUrl,
}: any): Promise<ExpressCheckoutSummary> => {
  return axios
    .post(summaryUrl, undefined, { headers: { shopid: process.env.SHOP_ID } })
    .then(({ data }) => data)
    .then(CheckoutModel.expressCheckoutSummaryFromServerFormat);
};

/**
 * Creates payment session
 * @see https://petsdeli.atlassian.net/wiki/spaces/DOKUMENTAT/pages/312803348/
 */
export const createPaymentSession = (
  payload: PaymentSessionRequest
): Promise<PaymentResponseType> =>
  axios
    .post<PaymentResponseType>(
      `${process.env.API_ORIGIN_PAYMENT}/optile/paymentSession`,
      payload
    )
    .then((x) => camelCaseKeys(x.data, { deep: true }));
/**
 * Updates payment session
 * @see https://petsdeli.atlassian.net/wiki/spaces/DOKUMENTAT/pages/312803348/
 */
export const updatePaymentSession = (
  sessionId: string,
  payload: PaymentSessionRequest
): Promise<PaymentResponseType> =>
  axios
    .post<PaymentResponseType>(
      `${process.env.API_ORIGIN_PAYMENT}/optile/paymentSession/${sessionId}`,
      payload
    )
    .then((x) => camelCaseKeys(x.data, { deep: true }));

export const postOperation = (
  operationUrl: string,
  options: OperationOptions
) =>
  axiosWithoutRequestInterceptor
    .post(operationUrl, {
      allowRecurrence: options.allowRecurrence,
      autoRegistration: options.autoRegistration,
    })
    .then((x) => x.data);

/** @TODO : Add detailed comment from Optile documentations */
export type PaymentUrlParam = {
  /** e.g 15819-27257 */
  shortId?: string;
  /** e.g 609269551dab52040cecef18u */
  customerRegistrationId?: string;
  /** e.g OK */
  interactionReason?: string;
  /** e.g 00000.STRIPE-PI.succeeded */
  resultCode?: string;
  /** e.g 62c2beaabef2dd0b8b410acfc */
  longId?: string;
  /** e.g 1656929955-jvl5dl56lbc0u */
  transactionId?: string;
  /** e.g PROCEED */
  interactionCode?: string;
  /** e.g 28.90 */
  amount?: string;
  /** e.g Pets+Deli+Tonius+GmbH */
  reference?: string;
  /** e.g EUR */
  currency?: string;
  /** e.g optile */
  paymentProvider?: string;
  /** e.g VISA */
  paymentMethod?: string;
  /** e.g 1656929955-jvl5dl56lbc0u */
  sessionId?: string;
};

/**
 * Wrapper function to call actionUrl retrieved from url prams after payment redirection
 * This function flushes cart on backend
 * @example
 * actionUrl : payment/paymentSession/1656929955-jvl5dl56lbc0u/close/optile
 */
export const finishPayment = (actionUrl: string, data?: PaymentUrlParam) =>
  axios.post(actionUrl, data).then((x) => x.data);

export const fetchPaymentSummary = (sessionId: string) =>
  axios
    .post(
      `${process.env.API_ORIGIN_PAYMENT}/optile/paymentSession/express/${sessionId}/summary`
    )
    .then((x) => x.data);

export type MolliePayload = {
  method: string;
  redirectUrl: string;
  cancelUrl: string;
  mandateId?: string;
};

type MollyCheckoutResponse = {
  redirectUrl: string;
};

/**
 * Creates Mollie payment session.
 */
export const createMolliePayment = async (
  requestId: string,
  payload: MolliePayload
): Promise<MollyCheckoutResponse> =>
  axios
    .post(
      `${process.env.API_ORIGIN_PAYMENT}/mollie/payment/${requestId}`,
      payload
    )
    .then(({ data }) => data);

type ReauthenticatePaymentInput = {
  method: string;
  customerId: number;
  mandateId?: string;
  redirectUrl?: string;
  cancelUrl?: string;
};

type ReauthenticatePaymentResponse = {
  redirectUrl: string;
};

/**
 * Creates Mollie payment session to reauthenticate payment method for customer that have Optile as saved
 * payment method.
 */
export const reauthenticatePayment = async ({
  redirectUrl = `${location.protocol}//${location.host}/account/subscriptions?success=1`,
  cancelUrl = `${location.protocol}//${location.host}/account/subscriptions`,
  ...others
}: ReauthenticatePaymentInput): Promise<ReauthenticatePaymentResponse> =>
  axios
    .post(`${process.env.API_ORIGIN_PAYMENT}/mollie/reauthentication/payment`, {
      redirectUrl,
      cancelUrl,
      ...others,
    })
    .then(({ data }) => data);

type CheckReauthenticationRequiredInput = {
  customerId: number;
};

export const PaymentMethodOption = {
  CreditCard: 'creditcard',
  PayPal: 'paypal',
} as const;

export type PaymentMethodOption =
  (typeof PaymentMethodOption)[keyof typeof PaymentMethodOption];

interface BasicResponse {
  id: string;
  mode: 'test' | 'production';
  method: PaymentMethodOption;
  resouce: 'mandate';
}

interface CreditCardResponse extends BasicResponse {
  method: 'creditcard';
  details: {
    cardHolder: string;
    cardNumber: string;
    cardLabel: string;
    cardFingerprint: string;
    cardExpiryDate: string;
  };
}
interface PaypalResponse extends BasicResponse {
  method: 'paypal';
  details: {
    consumerName: string;
    consumerAccount: string;
  };
}

export type CheckReauthenticationRequiredResponse = {
  reauth: boolean;
  mandates: Array<CreditCardResponse | PaypalResponse>;
};

/**
 * Checks if reauthentication of payment method is required.
 * If reauthentication is required and the customer has valid mandates, these are returned as well.
 */
export const checkReauthenticationRequired = async ({
  customerId,
}: CheckReauthenticationRequiredInput): Promise<CheckReauthenticationRequiredResponse> =>
  axios
    .get(
      `${process.env.API_ORIGIN_PAYMENT}/mollie/reauthentication/isRequired/${customerId}`
    )
    .then(({ data }) => data);

export interface ApplePayMerchantSession {
  displayName: string;
  domainName: string;
  epochTimestamp: number;
  expiresAt: number;
  merchantIdentifier: string;
  merchantSessionIdentifier: string;
  nonce: string;
  operationalAnalyticsIdentifier: string;
  pspId: string;
  retries: number;
  signature: string;
}

interface ApplePayPaymentResponse {
  redirectUrl: string;
  errors?: { error: ApplePayError[]; type: string };
}

/**
 * Creates Apple Pay merchant session via Mollie
 */
export const createApplePayMerchatSession = (
  validationUrl: string,
  domain: string
): Promise<ApplePayMerchantSession> =>
  axios
    .post(`${process.env.API_ORIGIN_PAYMENT}/mollie/applePay/session`, {
      domain,
      validationUrl,
    })
    .then(({ data }) => data);

/**
 * Creates Apple Pay payment session via Mollie
 */
export const createApplePayPayment = ({
  payment,
}: {
  payment: ApplePaymentPaymentRequest;
}): Promise<ApplePayPaymentResponse> =>
  axios
    .post(`${process.env.API_ORIGIN_PAYMENT}/mollie/applePay/payment`, payment)
    .then(({ data }) => data);

export type CartRequest = {
  customerId?: number;
  discount?: { type: CartDiscountType; code?: string };
  items: { variantId: number; quantity: number }[];
  shipping?: EffectiveCart['shipping'];
};

export interface PaypalExpressCreateOrderPayload {
  cart: CartRequest;
  checkoutId: string;
  method: 'paypal';
  listParams: {
    cancelUrl: string;
    summaryUrl: string;
    returnUrl: string;
  };
}

interface PaypalExpressCreateOrderResponse {
  requestId: string;
  paypalOrderId: string;
}

interface PaypalExpressAuthPaymentPayload {
  subscribeNewsletter?: boolean | undefined;
  cart: CartRequest;
}

interface PaypalExpressAuthPaymentResponse {
  actionReq?: 'charged';
  redirectUrl: string;
}

interface PaypalExpressInitPaymentResponse {
  redirectUrl: string;
}

/**
 * Creates Paypal Express order
 */
export const createPaypalExpressOrder = ({
  payload,
}: {
  payload: PaypalExpressCreateOrderPayload;
}): Promise<PaypalExpressCreateOrderResponse> =>
  axios
    .post(`${process.env.API_ORIGIN_PAYMENT}/paypal/order`, {
      ...payload,
    })
    .then(({ data }) => data);

/**
 * Initialize Paypal Express payment
 */
export const initPayPalExpressPayment = (
  requestId: string
): Promise<PaypalExpressInitPaymentResponse> =>
  axios
    .post(`${process.env.API_ORIGIN_PAYMENT}/paypal/init/${requestId}`)
    .then(({ data }) => data);

/**
 * Authorize Paypal Express payment
 */
export const authPaymentPaypalExpress = (
  requestId: string,
  payload: PaypalExpressAuthPaymentPayload
): Promise<PaypalExpressAuthPaymentResponse> =>
  axios
    .post(`${process.env.API_ORIGIN_PAYMENT}/paypal/auth/${requestId}`, {
      ...payload,
    })
    .then(({ data }) => data);

interface PaypalExpressCapturePaymentResponse {
  redirect: string;
  status: boolean;
}

/**
 * Capture Paypal Express payment
 */
export const capturePaymentPaypalExpressPayment = (
  requestId: string | undefined
): Promise<PaypalExpressCapturePaymentResponse> =>
  axios
    .post(`${process.env.API_ORIGIN_PAYMENT}/paypal/capture/${requestId}`)
    .then(({ data }) => data);

export interface ValidateShippingDetailsResponse {
  valid: boolean;
  missingFields?: [
    { key: 'lastName'; type: 'text' },
    { key: 'street'; type: 'text' },
  ];
}

/**
 * Validates shipping details
 * Used in express checkout to validate shipping details
 */
export const validateShippingDetails = (
  requestId: string
): Promise<ValidateShippingDetailsResponse> =>
  axios
    .get(
      `${process.env.API_ORIGIN_PAYMENT}/validate-shipping-details/${requestId}`
    )
    .then(({ data }) => data);

/**
 * Updates shipping details
 * Used in express checkout to update shipping details
 */
export const updateShippingDetails = ({
  requestId,
  payload,
}: {
  requestId: string;
  payload: {
    lastName?: string;
    street?: string;
  };
}): Promise<void> =>
  axios
    .post(
      `${process.env.API_ORIGIN_PAYMENT}/update-shipping-details/${requestId}`,
      payload
    )
    .then(({ data }) => data);
