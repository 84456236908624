import { useExperience } from '@ninetailed/experience.js-next';
import { useNinetailedAbTestContext } from 'contexts/ninetailed-ab-test';
import { IBonusPointCampaignFields, IGlobalData } from 'global';
import React, { useEffect, useMemo } from 'react';
import { isVariantRef, MyVariant, parseExperiences } from 'utils/ninetailed';

type Campaign = MyVariant<IBonusPointCampaignFields>;

export interface GlobalLoyaltyCampaign {
  loading: boolean;
  campaign: null | Campaign;
}

/**
 * Retrieve loyalty campaign configuration based on personalization.
 */
const useGlobalLoyaltyCampaign = (
  globalData: IGlobalData
): GlobalLoyaltyCampaign => {
  const { setToHeader } = useNinetailedAbTestContext();
  const pointCampaignBaseline = React.useMemo(() => {
    return {
      ...globalData.fields.bonusPointCampaign.fields,
      id: globalData?.fields.bonusPointCampaign.sys.id || '',
    };
  }, [globalData]);

  const experiences = parseExperiences({
    entry: globalData.fields.bonusPointCampaign,
  });

  const { variant, loading, error, experience, variantIndex } =
    useExperience<Campaign>({
      baseline: pointCampaignBaseline,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      experiences: experiences,
    });

  const globalLoyaltyCampaign: GlobalLoyaltyCampaign = React.useMemo(() => {
    if (error) {
      return {
        loading: false,
        campaign: { ...pointCampaignBaseline },
      };
    }

    if (loading) {
      return {
        loading: true,
        campaign: { ...pointCampaignBaseline },
      };
    }

    if (variant && !isVariantRef(variant)) {
      return {
        loading: false,
        campaign: {
          // When no variant is not found, variant is null.
          // here we have to assign default
          ...pointCampaignBaseline,
          ...variant,
          id: variant.id,
        },
      };
    } else {
      return {
        loading: false,
        campaign: {
          ...pointCampaignBaseline,
        },
      };
    }
  }, [error, loading, variant, pointCampaignBaseline]);

  const experienceId = useMemo(() => {
    return experience?.id;
  }, [experience]);

  /**
   * Set to header
   */
  useEffect(() => {
    if (variantIndex !== undefined && experienceId) {
      setToHeader({
        experienceId,
        variant: variantIndex,
      });
    }
  }, [experienceId, setToHeader, variantIndex]);

  return globalLoyaltyCampaign;
};

export default useGlobalLoyaltyCampaign;
