import { ExpressCheckoutSummary } from 'interfaces/checkout';
import {
  isSubscriptionItem,
  PdProductItem,
  PdSubscriptionProductItem,
} from 'interfaces/line-item';
import { ProductType } from 'interfaces/product';
import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import { Gtm } from 'tracking/gtm';

interface GtmCheckoutItem {
  interval?: string;
  list?: string;
  price: number;
  productId: number;
  productTitle: string;
  productType: ProductType;
  quantity: number;
  sku: string;
  tags: string;
  taxRate: number;
  variantId: number;
  variantTitle: string;
}
interface TrackingContextType {
  clearTrackedListStorage: () => void;
  getProductList: (productId: number, defaultListName: string) => string;
  mapCheckoutItems: (
    items: Array<PdProductItem | PdSubscriptionProductItem>
  ) => GtmCheckoutItem[];
  mapPurchase: (summary: ExpressCheckoutSummary) => Gtm.Checkout;
  setTrackedList: (productId: number, list: string) => void;
}

const TrackingContext = createContext<TrackingContextType>({} as any);

const LIST_PERFORMANCE_KEY = 'pd:track-list-performance';

/**
 * A context used to track list names for performance analysis purposes
 */
export const TrackingContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [trackedListStorage, setTrackedListStorage, remove] = useLocalStorage<
    {
      productId: number;
      list: string;
    }[]
  >(LIST_PERFORMANCE_KEY, []);

  const currentTrackedListStorage = useMemo(
    () => trackedListStorage ?? [],
    [trackedListStorage]
  );

  /**
   * This function is used to set the list name for a product.
   * It will only set the list name if the product has not been tracked yet.
   * This is to prevent the list name from being overwritten.
   */
  const setTrackedList = useCallback(
    (productId: number, list: string) => {
      const itemHasBeenTracked = currentTrackedListStorage.some(
        (item) => item.productId === productId
      );

      if (itemHasBeenTracked) return;
      setTrackedListStorage([
        ...currentTrackedListStorage,
        {
          productId,
          list: list,
        },
      ]);
    },
    [currentTrackedListStorage, setTrackedListStorage]
  );

  const getProductList = useCallback(
    (productId: number, defaultListName: string): string => {
      const list = trackedListStorage?.find(
        (item) => item.productId === productId
      )?.list;
      if (!list) {
        setTrackedList(productId, defaultListName);
        return defaultListName;
      }
      return list;
    },
    [trackedListStorage, setTrackedList]
  );

  /**
   * This function is used to map the line items to the format required by GTM.
   * It will also add the list name to the line item.
   */
  const mapCheckoutItems = (
    items: Array<PdProductItem | PdSubscriptionProductItem>
  ): GtmCheckoutItem[] => {
    return items.map((item) => {
      const interval = isSubscriptionItem(item) ? item.interval : undefined;
      const list = getProductList(item.productId, '');

      return {
        interval,
        list,
        price: item.price,
        productId: item.productId,
        productTitle: item.productTitle,
        productType: item.productType,
        quantity: item.quantity,
        sku: item.sku,
        tags: item.tags,
        taxRate: item.taxRatios
          .map(([rate, ratio]) => rate * ratio)
          .reduce((sum, rate) => sum + rate, 0),
        variantId: item.variantId,
        variantTitle: item.variantTitle,
      };
    });
  };

  const sumTaxBrackets = (
    sum: number,
    bracket: [number, number] | { amount: number }
  ): number => sum + ('amount' in bracket ? bracket.amount : bracket[1]);

  /**
   * This function is used to map the checkout summary to the format required by GTM.
   */
  const mapPurchase = (summary: ExpressCheckoutSummary): Gtm.Checkout => {
    const {
      customer,
      payment,
      requestId,
      effectiveCart: {
        cartTotal,
        discount,
        items,
        itemsTotal,
        shipping,
        taxes,
      },
    } = summary;

    const itemsVat = taxes.items.reduce(sumTaxBrackets, 0);
    const shippingVat = taxes.shipping?.reduce(sumTaxBrackets, 0) || 0;
    const discountVat = Math.abs(
      taxes.discount?.reduce(sumTaxBrackets, 0) || 0
    );
    return {
      requestId,
      customer: {
        id: customer.id,
        email: customer.email,
        ordersCount: customer.ordersCount,
      },
      totalAmount: cartTotal,
      totalVat: itemsVat + shippingVat - discountVat,
      itemsTotal,
      payment: {
        gateway: payment.gateway,
        method: payment.method,
      },
      shipping: {
        type: shipping.type,
        price: shipping.price,
        vat: shippingVat,
      },
      discount: discount
        ? {
            type: discount.type,
            code: discount.code,
            value: discount.fixedValue,
          }
        : undefined,
      items: mapCheckoutItems(items),
    };
  };

  /**
   * This function is used to clear the tracked list storage.
   * This is used after a purchase has been made.
   */
  const clearTrackedListStorage = (): void => remove();

  return (
    <TrackingContext.Provider
      value={{
        clearTrackedListStorage,
        getProductList,
        mapCheckoutItems,
        mapPurchase,
        setTrackedList,
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
};

export const useTrackingContext = (): TrackingContextType =>
  useContext(TrackingContext);
