import { AxiosRequestConfig } from 'axios';
import cookie from 'cookie';
import merge from 'lodash/merge';
import { useEffect } from 'react';
import { axios } from 'utils/axios';
import { CookieName } from './use-set-to-cookie';

/**
 * @NOTE : we might as well delete this file if we can just read values from cookie
 */

const HeaderNameMap = {
  [CookieName.Audiences]: 'audiences',
  [CookieName.Experiments]: 'ninetailed',
} as const;

export const generateApiInterceptor = (
  config: AxiosRequestConfig
): AxiosRequestConfig => {
  const { withoutAudience, withoutNinetailed } = config;

  // Automatically add headers
  const headers: { audiences?: string; ninetailed?: string } = {};
  const parsedCookie = cookie.parse(document.cookie);

  for (const key in CookieName) {
    const value = CookieName[key];
    if (
      parsedCookie[value] &&
      !(key === CookieName.Audiences && withoutAudience !== true)
    ) {
      headers[HeaderNameMap[value]] = parsedCookie[value];
    }
  }

  // Add a condition to exclude Ninetailed header
  if (withoutNinetailed) delete headers.ninetailed;

  return merge(config, {
    /** @TODO : update api header config as it returns error */
    headers,
  });
};

/**
 * @TODO : check if update is necessary. I think once this hook is called, we don't need to update it everytime cookie is updated
 * */
const useExperimentInterceptor = (): void => {
  let requestInterceptor: number | null = null;

  // Don't add interceptor if it's server side. Next.js server is singleton!
  if (typeof window !== 'undefined') {
    requestInterceptor = axios.interceptors.request.use((config) =>
      generateApiInterceptor(config)
    );
  }

  useEffect(() => {
    return () => {
      if (requestInterceptor !== null) {
        axios.interceptors.request.eject(requestInterceptor);
      }
    };
  });
};

export default useExperimentInterceptor;
