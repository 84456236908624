import cookie from 'cookie';
import { useEffect } from 'react';

export const CookieName = {
  Audiences: 'pd_audiences',
  Experiments: 'pd_ninetailed',
} as const;

export type CookieName = (typeof CookieName)[keyof typeof CookieName];

type Input = {
  cookieId?: CookieName;
  cookieValue?: string;
};

const useSetToCookie = ({ cookieId, cookieValue }: Input): void => {
  useEffect(() => {
    if (cookieValue) {
      const date = new Date();
      date.setDate(date.getDate() + 7);
      document.cookie = cookie.serialize(
        cookieId || CookieName.Experiments,
        cookieValue,
        {
          expires: date,
          path: '/',
          domain: process.env.COOKIE_DOMAIN,
        },
      );
    } else {
      // delete cookie value by setting expires
      const date = new Date();
      date.setTime(0);
      document.cookie = cookie.serialize(
        cookieId || CookieName.Experiments,
        '',
        {
          expires: date,
          path: '/',
          domain: process.env.COOKIE_DOMAIN,
        },
      );
    }
  }, [cookieId, cookieValue]);
};

export default useSetToCookie;
