import { useExperience, useNinetailed } from '@ninetailed/experience.js-next';
import { ExperienceMapper } from '@ninetailed/experience.js-utils';
import { useNinetailedAbTestContext } from 'contexts/ninetailed-ab-test';
import { GetAbTestByIdQuery } from 'generated/graphql';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce, usePrevious } from 'react-use';
import { isVariantRef } from 'utils/ninetailed';
import { omitNullableHandler } from 'utils/type-helper';

type ProfileState = any;

type Input = {
  /** Id of base entry attached to Experiment config */
  baseEntryId: string;
  /** if true, resolved variant is set to header to communicate with BE */
  shouldAddToHeader?: boolean;
  /**
   * Ref Object to element used for tracking purpose.
   * If A/B test is associated with a component, this should be specified
   * */
  elementRef?: React.RefObject<Element>;
  /**
   * Callback when a user becomes eligible to a/b test or the other way around.
   * Useful when we have to recalculate based on test variant.
   * e.g discount for NC but not for EC.
   */
  onExperimentChange?: (profile?: ProfileState) => void | Promise<void>;
};

type Output = {
  variant: number | undefined;
  loading: boolean;
  testData: unknown | undefined;
  baseUrl?: string;
  redirectUrl?: string;
  /** Trigger tracking for Ninetailed experience. */
  trackComponentViewFn: () => void;
  experienceId?: string;
};

type DeepRequired<T> = {
  [P in keyof T]-?: NonNullable<T[P]> extends object
    ? DeepRequired<NonNullable<T[P]>>
    : NonNullable<T[P]>;
};

type RequiredGetAbTestByIdQuery = DeepRequired<GetAbTestByIdQuery>;

const useExperiment = ({
  baseEntryId,
  shouldAddToHeader,
  elementRef,
  onExperimentChange,
}: Input): Output => {
  const [exVariant, setExVariant] = useState<number | undefined>();
  const [testData, setTestData] = useState<unknown | undefined>();

  const { setToHeader, getTestEntry, isLoading, deleteHeader } =
    useNinetailedAbTestContext();

  const entryPromise = getTestEntry(baseEntryId);

  const [baseLine, setBaseline] = useState<GetAbTestByIdQuery | undefined>();

  useEffect(() => {
    entryPromise.then((entry) => {
      entry && setBaseline(entry);
    });
  }, [entryPromise]);

  const _baseline = useMemo(() => {
    if (!baseLine) return { id: '' };
    return {
      id: baseLine?.experimentConfig?.sys.id || '',
      ...baseLine.experimentConfig,
    };
  }, [baseLine]);

  /**
   * Sample
   * @see https://github.com/ninetailed-inc/graphql-example/blob/main/lib/helpers.js
   */
  const parsed = useMemo(() => {
    try {
      const mappedExperiences = (
        (baseLine?.experimentConfig?.ntExperiencesCollection?.items ||
          []) as RequiredGetAbTestByIdQuery['experimentConfig']['ntExperiencesCollection']['items']
      )
        .map((experience) => {
          return {
            id: experience.sys.id || '',
            name: experience.ntName || '',
            type: experience.ntType || '',
            // nt config type is JSON type
            config: experience.ntConfig,
            audience: {
              id: experience.ntAudience.ntAudienceId || '',
            },
            variants: experience.ntVariantsCollection.items
              .filter((variant) => variant.__typename === 'ExperimentConfig')
              .map((variant) => {
                if (variant.__typename === 'ExperimentConfig') {
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  const { sys, ...others } = variant;
                  return {
                    id: variant?.sys.id || '', // Required
                    ...others,
                  };
                }
              })
              .filter(omitNullableHandler),
          };
        })
        .filter((experience) =>
          ExperienceMapper.isExperienceEntry<{
            id: string;
          }>(experience)
        )
        .map((experience) => ExperienceMapper.mapExperience(experience));

      return mappedExperiences;
    } catch (e) {
      console.log(e);
      return [];
    }
  }, [baseLine?.experimentConfig?.ntExperiencesCollection?.items]);

  const { audience, experience, loading, status, variant, variantIndex } =
    useExperience({
      baseline: _baseline,
      experiences: parsed,
    });

  useEffect(() => {
    if (!loading && status === 'success') {
      if (variant && !isVariantRef(variant)) {
        setExVariant(variantIndex);
      } else {
        setExVariant(variantIndex);
      }
    } else if (status === 'error') {
      setExVariant(0);
      console.warn('error on Ninetailed API');
    }
  }, [loading, status, variant, variantIndex]);

  useEffect(() => {
    if (!loading && status === 'success' && variant && !isVariantRef(variant)) {
      try {
        setTestData(variant.testData);
      } catch (error) {
        setTestData(undefined);
      }
    }
  }, [loading, status, variant]);

  const [experienceId, setExperienceId] = useState<undefined | string>(
    undefined
  );

  useEffect(() => {
    setExperienceId(experience?.id);
  }, [experience]);

  useEffect(() => {
    if (shouldAddToHeader && exVariant !== undefined && experienceId) {
      setToHeader({
        experienceId,
        variant: exVariant,
      });
    }
  }, [exVariant, experienceId, setToHeader, shouldAddToHeader]);

  const preExperienceId = usePrevious(experienceId);

  /**
   * Remove header once user is not eligible to experience anymore.
   * e.g Assigned NC logins and becomes EC.
   */
  useEffect(() => {
    if (preExperienceId && preExperienceId !== experienceId && !experienceId) {
      deleteHeader({ experienceId: preExperienceId });
    }
  }, [deleteHeader, experienceId, preExperienceId]);

  const {
    trackComponentView,
    observeElement,
    unobserveElement,
    onProfileChange,
  } = useNinetailed();

  useEffect(() => {
    let detouchListeners: any = null;
    if (onExperimentChange) {
      detouchListeners = onProfileChange((pro) => {
        if (preExperienceId !== experienceId) {
          onExperimentChange(pro);
        }
      });
    }

    return () => {
      detouchListeners && detouchListeners();
    };
  }, [onExperimentChange, experienceId, onProfileChange, preExperienceId]);

  const hasTracked = useRef(false);

  const trackComponentViewFn = useCallback(() => {
    if (!elementRef && hasTracked.current === false) {
      // pass down fake dom to suppress error
      trackComponentView({
        element: document.createElement('div'),
        experience,
        audience,
        variant: variant as any,
        variantIndex,
      });
      hasTracked.current = true;
    }
  }, [
    audience,
    elementRef,
    experience,
    trackComponentView,
    variant,
    variantIndex,
  ]);

  /**
   * Set up trackComponentView to fires event to google analytics plugins
   * Due to implementation, debounce is needed
   */
  useDebounce(trackComponentViewFn, 750, [trackComponentViewFn]);

  /**
   * Set up tracking effect to fires event to google analytics plugins
   */
  useEffect(() => {
    const componentElement = elementRef?.current;

    if (componentElement) {
      observeElement({
        element: componentElement,
        experience,
        audience,
        variant: variant as any,
        variantIndex,
      });
    }

    return () => {
      if (componentElement) {
        unobserveElement(componentElement);
      }
    };
  }, [
    audience,
    elementRef,
    experience,
    observeElement,
    unobserveElement,
    variant,
    variantIndex,
  ]);

  const baseUrl = useMemo(() => {
    return !isVariantRef(variant) && variant ? variant.baseUrl : undefined;
  }, [variant]);

  const redirectUrl = useMemo(() => {
    return !isVariantRef(variant) && variant ? variant.redirectUrl : undefined;
  }, [variant]);

  return {
    variant: exVariant,
    loading: loading || isLoading,
    testData,
    baseUrl,
    redirectUrl,
    trackComponentViewFn,
    experienceId:
      experienceId && exVariant ? `${experienceId}:${exVariant}` : undefined,
  };
};

export default useExperiment;
