import { Reference, VariantRef } from '@ninetailed/experience.js';
import {
  AudienceEntryLike,
  AudienceMapper,
  BaselineWithExperiencesEntry,
  Entry,
  ExperienceEntryLike,
  ExperienceMapper,
} from '@ninetailed/experience.js-utils-contentful';
import { contentfulClient } from 'connectors/contentful';
import { ShopId } from 'constants/shop';
import { CustomerSubscriptionStatus } from 'interfaces/customer';

type OurTraits = Partial<{
  ordersCount: number;
  createdAt: number;
  subscriptionStatus: CustomerSubscriptionStatus;
  isUserLoggedIn: boolean;
  /**
   * Segmentation
   * @see : https://petsdeli.atlassian.net/wiki/spaces/DOKUMENTAT/pages/1974042625/Segmentation
   * */
  segmentation: string;
  /** Breed Segmentation */
  breedSegmentation: string;
  /** Nutrition Segmentation */
  needSegmentation: string;
  /** PetType. Should be used to differentiate contents for dogs or cats  */
  petType: 'dogs' | 'cats';
  /** shopId. Should be used to differentiate audience in each shops  */
  shopId: ShopId;
  /** Snacl likelihood */
  snackLikelihood: number;
  /** Supplement likelihood */
  supplementLikelihood: number;
}>;

/**
 * Assert traits property as our traits because Ninetailed traits is typed as JSON type.
 */
export function isTraits(val: any): val is OurTraits {
  return typeof val === 'object' && typeof val['ordersCount'] === 'number';
}

export type MyVariant<Fields = unknown> = Omit<
  Fields,
  'nt_variants' | 'nt_audience' | 'nt_experiences'
> &
  Reference;

export function isVariantRef(val: any): val is VariantRef {
  return typeof val === 'object' && val['hidden'] === 'boolean';
}

type SingularBlock = Entry | BaselineWithExperiencesEntry;
type SingularOrArrayBlock =
  | SingularBlock
  | Entry[]
  | BaselineWithExperiencesEntry[];

const hasExperiences = (
  entry: SingularBlock
): entry is BaselineWithExperiencesEntry => {
  return (
    (entry as BaselineWithExperiencesEntry).fields.nt_experiences !== undefined
  );
};

/**
 * @TODO : add comment
 */
export const parseExperiences = ({ entry }: { entry?: SingularBlock }) => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const _hasEx = hasExperiences(entry);

    return _hasEx
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        entry.fields.nt_experiences
          .filter((experience) =>
            ExperienceMapper.isExperienceEntry(experience)
          )
          .map((experience) =>
            ExperienceMapper.mapCustomExperience(experience, (variant) => ({
              ...variant.fields,
              id: variant.sys.id,
            }))
          )
      : [];
  } catch (error) {
    return [];
  }
};

export const getAllExperiences = async () => {
  const entries = await contentfulClient.getEntries({
    content_type: 'nt_experience',
  });
  return (entries.items as ExperienceEntryLike[])
    .filter(ExperienceMapper.isExperienceEntry)
    .map(ExperienceMapper.mapExperience);
};

export const getAllAudiences = async () => {
  const entries = await contentfulClient.getEntries({
    content_type: 'nt_audience',
  });
  return (entries.items as AudienceEntryLike[])
    .filter(AudienceMapper.isAudienceEntry)
    .map(AudienceMapper.mapAudience);
};
