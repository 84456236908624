import cn from 'classnames';
import { useCart } from 'contexts/cart';
import { useCartState } from 'modules/cart/state';
import React, { useCallback, useEffect, useState } from 'react';
import { MODAL_STATUS, useCartStore } from 'stores/carts';
import { showErrorToast } from 'utils/toasts';
import styles from './apple-pay-button.module.css';
import useApplePayButton from './state';

interface ApplePayButtonProps {
  className?: string;
}

const APPLE_PAY_SESSION_VERSION = 6;

const ApplePayButton: React.FC<ApplePayButtonProps> = ({ className }) => {
  const { oosItems } = useCart();
  const { setModalOosStatus } = useCartStore();
  const [isApplePayAvailable, setIsApplePayAvailable] = useState(false);
  const { effectiveCart } = useCartState();

  useEffect(() => {
    if (window.ApplePaySession) {
      setIsApplePayAvailable(true);
    }

    return () => setIsApplePayAvailable(false);
  }, []);

  const { applePaySession, handleApplePayEvents, request } =
    useApplePayButton(effectiveCart);

  const onApplePay: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.preventDefault();
      if (oosItems.length > 0) {
        setModalOosStatus(MODAL_STATUS.APPLEPAY_OPEN);
        return;
      }
      if (window.ApplePaySession && request) {
        try {
          setModalOosStatus(MODAL_STATUS.CLOSE);
          applePaySession.current = new ApplePaySession(
            APPLE_PAY_SESSION_VERSION,
            request
          );

          handleApplePayEvents(applePaySession.current);

          applePaySession.current.begin();
        } catch (error) {
          console.error(error);
          showErrorToast({
            error: 'error:unknown',
            caller: 'ApplePayButton - Component',
          });
        }
      }
    },
    [
      oosItems.length,
      request,
      setModalOosStatus,
      applePaySession,
      handleApplePayEvents,
    ]
  );

  if (!isApplePayAvailable) return null;

  return (
    <button
      className={cn(
        styles.applePayButton,
        styles.applePayButtonBlack,
        className
      )}
      onClick={onApplePay}
    />
  );
};

export default ApplePayButton;
