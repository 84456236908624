import IconCheckmark from 'assets/icons/checkmark-action-done.svg';
import cn from 'classnames';
import Link from 'next/link';
import React, {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  forwardRef,
} from 'react';
import { useIntl } from 'react-intl';
import IconAnimatedSpinner from '../../assets/icons/animated-spinner.svg';

export type Layout = 'contained' | 'outline' | 'text';

export type ButtonProps = {
  actionDone?: boolean;
  as?: string;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  focus?: boolean;
  href?: string;
  iconLeft?: boolean;
  iconRight?: boolean;
  layout?: Layout;
  loading?: boolean;
  size: 'xs' | 'base' | 'large';
  styleDisabled?: boolean;
  target?: '_blank' | '_top' | '_self';
  translationValues?: Record<
    string,
    | string
    | number
    | boolean
    | ReactElement<unknown, string | JSXElementConstructor<unknown>>
    | Iterable<ReactNode>
    | undefined
  >;
  type?: 'button' | 'submit' | 'reset';
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
};

const LayoutClassNames: {
  [key in Layout]: {
    actionDone: string;
    default: string;
    disabled: string;
    focus: string;
  };
} = {
  contained: {
    actionDone: 'bg-green-900 text-white lg:hover:bg-green-900',
    default:
      'bg-secondary-700 rounded-full text-white lg:hover:bg-secondary-900',
    disabled:
      'bg-gray-100 text-gray-400 lg:hover:bg-gray-100 lg:hover:text-gray-400',
    focus: 'border-[3px] border-secondary-100',
  },
  outline: {
    actionDone: 'border border-green-900 text-green-900',
    default:
      'border border-secondary-700 text-secondary-700 lg:hover:border-secondary-900 lg:hover:text-secondary-900',
    disabled: 'border-gray-300 text-gray-300 border',
    focus: 'border-[3px] border-secondary-700 text-secondary-700',
  },
  text: {
    actionDone: 'text-green-900',
    default: 'text-secondary-700 lg:hover:text-purple-700',
    disabled: 'text-gray-300',
    focus: 'border-1 border-secondary-700 text-secondary-700',
  },
};

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (props, ref) => {
    const {
      actionDone,
      as,
      children,
      className,
      disabled,
      focus,
      href,
      iconLeft,
      iconRight,
      layout = 'contained',
      loading,
      size,
      styleDisabled,
      target = '_self',
      translationValues,
      type,
      onClick,
    } = props;
    const intl = useIntl();

    const icon = actionDone && (
      <IconCheckmark
        className={cn(
          'h-auto',
          size === 'xs' && 'w-3',
          size === 'base' && 'w-3.5',
          size === 'large' && 'w-4'
        )}
      />
    );

    const buttonClassName = cn(
      'focus-default inline-flex items-center justify-center leading-none rounded-full text-sm transition-colors duration-700 ease-in-out w-full font-medium cursor-pointer disabled:cursor-default',
      layout === 'text' ? 'p-0' : 'px-3 py-2',
      !actionDone && !styleDisabled && LayoutClassNames[layout].default,
      focus && LayoutClassNames[layout].focus,
      styleDisabled && LayoutClassNames[layout].disabled,
      actionDone && LayoutClassNames[layout].actionDone,
      size === 'xs' && 'text-xs h-[30px] leading-[18px]',
      size === 'base' && 'h-9 text-sm leading-[21px]',
      size === 'large' && 'h-[42px] text-base leading-normal',
      className
    );

    const buttonChildren =
      typeof children === 'string'
        ? intl.formatMessage({ id: children }, translationValues)
        : children;

    // Return a link element when href is set
    if (href) {
      return (
        <Link as={as} href={href} passHref>
          <a
            onClick={onClick}
            className={buttonClassName}
            target={target}
            ref={ref as React.RefObject<HTMLAnchorElement>}
          >
            <span className="block">{buttonChildren}</span>
          </a>
        </Link>
      );
    }

    return (
      <button
        ref={ref as React.RefObject<HTMLButtonElement>}
        className={buttonClassName}
        disabled={disabled}
        onClick={onClick}
        type={type}
      >
        {loading ? (
          <IconAnimatedSpinner className="h-auto w-5" />
        ) : (
          <div className="flex items-center justify-end gap-2 leading-none">
            {iconLeft && icon}
            {buttonChildren}
            {iconRight && icon}
          </div>
        )}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
